ion-label {
    color: #000;
    font-weight: 600;
}

ion-text {
    color: #000;
}

.card-pendente {
    background-color: #e0ffff;
}

.card-pendente ion-text,
.card-pendente ion-label,
.card-pendente ion-card-title {
    color: blue !important;
}

.card-agendado {
    background-color: #bddef4;
}

.card-suspenso {
    background-color: rgba(255, 0, 0, .25) !important;
}

.card-realizado {
    background-color: #afa;
}

.card-andamento {
    background-color: #ff9;
}

.card-cancelado {
    background-color: orange;
}

.card-semrh {
    background-color: pink;
}

.card-s_maquina {
    background-color: #d8c3e2;
}

.card-visita {
    background-color: #c9c7c7;
}

.react-calendar__tile--active {
    background: #f16621;
    color: white;
}

.card-danger {
    background: rgba(255, 0, 0, 0.5);
}

.urgente {
    color: red;
}

.select-multi-options .alert-wrapper {
    width: 90%;
    max-width: 90%;
}

.select-multi-options .alert-checkbox-button {
    line-height: 1;
}

.select-multi-options .alert-checkbox-label {
    white-space: pre-line;
}

.select-action-sheet .select-interface-option {
    padding: 30px;
    font-size: 16px;
    color: #000000;
}